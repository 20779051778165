<template>
  <section
    class="box-height workdayManagement"
    style="height: 100%; padding: 0 15px !important"
  >
    <div style="background-color: #fff; padding-top: 20px">
      <el-row :gutter="20">
        <el-col :span="24"
          ><el-button
            type="primary"
            size="mini"
            @click="showDig"
            style="margin-left: 15px"
            >新增</el-button
          >
          <el-pagination
            style="float: right"
            :hide-on-single-page="page.total > 10 ? false : true"
            v-if="page.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-size="page.size"
            :pager-count="5"
            layout="total,prev, pager, next,sizes"
            :total="page.total"
            :page-sizes="[10, 20, 50, 100]"
          ></el-pagination
        ></el-col>
      </el-row>
      <div class="pd40" style="background-color: #fff; padding-bottom: 40px">
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          class="tabBorder custor"
        >
          <el-table-column prop="holidayName" label="节日名称">
          </el-table-column>
          <el-table-column prop="startDate" label="开始时间"> </el-table-column>
          <el-table-column prop="endDate" label="结束时间"> </el-table-column>
          <el-table-column prop="type" label="调整类型">
            <template #default="scope">
              <span>{{ scope.row.type == "HOLIDAY" ? "调整为休息日" : "调整为工作日" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="130">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="handleEdit(scope.row)"
                class="tabHref"
                >编辑</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                size="small"
                @click="delectRow(scope.row.id)"
                class="tabHref"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="page.total > 10 ? false : true"
          v-if="page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-size="page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </div>
      <!-- 新增同义词 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        append-to-body
        class="matchDialog"
        :title="dialogName"
        :close-on-click-modal="false"
        width="600px"
        :before-close="handleClose"
        destroy-on-close
      >
        <el-form
          @submit.native.prevent
          :model="addFrom"
          ref="addFrom"
          :rules="addFromRules"
          label-width="120px"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="节日名称：" prop="holidayName">
                <el-input v-model="addFrom.holidayName" style="width: 80%">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="调整类型：" prop="type">
                <el-radio-group v-model="addFrom.type">
                  <el-radio label="HOLIDAY">调整为休息日</el-radio>
                  <el-radio label="WORKDAY">调整为工作日</el-radio>
                </el-radio-group>
              </el-form-item></el-col
            >
            <el-col :span="24">
              <el-form-item label="起始时间：" prop="time">
                <el-date-picker
                  v-model="addFrom.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 80%"
                ></el-date-picker> </el-form-item
            ></el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="footer-title clearfix">
          <span>
            <el-button type="default" size="mini" @click="handleClose"
              >取消</el-button
            >
            <el-button
              type="primary"
              size="mini"
              :loading="saveloading"
              @click="save('addFrom')"
              >保存</el-button
            >
          </span>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import {
  sysHolidayAdd, //添加节假日
  sysHolidayDelete, //删除节假日
  sysHolidayEdit, //编辑节假日
  sysHolidayList, //节假日列表
} from "../../api/api";
export default {
  data() {
    return {
      page: {
        current: 1,
        size: 50,
        total: 0,
      },
      ListData: [],
      listLoading: false,
      addFrom: {
        holidayId: null,
        holidayName: null,
        startDate: null,
        endDate: null,
        type: null,
        time: [],
      },
      addFromRules: {
        holidayName: [
          { required: true, trigger: "blur", message: "节日名称不能为空" },
        ],
        type: [
          { required: true, trigger: "change", message: "调整类型不能为空" },
        ],
        time: [
          { required: true, trigger: "change", message: "起始时间不能为空" },
        ],
      },
      saveloading: false,
      dialogVisible: false,
      dialogName: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.getList();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.current = val;
      this.getList();
    },
    //获取列表
    getList() {
      this.listLoading = true;
      sysHolidayList(this.page ).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //新增
    showDig() {
      this.dialogVisible = true;
      this.dialogName = "新增";
    },
    //编辑
    handleEdit(row) {
      this.dialogVisible = true;
      this.dialogName = "编辑";
      this.addFrom = {
        holidayId: row.id,
        holidayName: row.holidayName,
        startDate: this.addFrom.time ? this.addFrom.time[0] : null,
        endDate: this.addFrom.time ? this.addFrom.time[1] : null,
        type: row.type,
        time:[row.startDate,row.endDate]
      };
    },
    //删除
    delectRow(id) {
      this.$confirm("确认删除吗?", "提示", {
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          sysHolidayDelete({holidayId:id}).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //关闭弹窗
    handleClose() {
      this.addFrom = {
        id: null,
        holidayName: null,
        startDate: null,
        endDate: null,
        type: null,
      };
      this.dialogVisible = false;
    },
    //保存
    save(name) {
      this.saveloading = true;
      return new Promise((resole) => {
        this.$refs[name].validate((valid) => {
          if (valid) {
            let RequestName = null;
            this.addFrom.startDate = this.addFrom.time
              ? this.addFrom.time[0]
              : "";
            this.addFrom.endDate = this.addFrom.time
              ? this.addFrom.time[1]
              : "";
            if (this.dialogName == "新增") {
              RequestName = sysHolidayAdd(this.addFrom);
            } else {
              RequestName = sysHolidayEdit(this.addFrom);
            }
            RequestName.then((res) => {
              if (res.success) {
                this.$message.success("成功");
                this.handleClose();
                this.getList();
                resole();
              }
            });
            this.saveloading = false;
          } else {
            this.saveloading = false;
          }
        });
      });
    },
  },
};
</script>

